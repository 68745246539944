import React, { useRef, useEffect, useState, useCallback } from 'react';
import Hls from 'hls.js';
import dashjs from 'dashjs';

const LiveStreamEmbed = React.memo(({ streamUrl, onAudioCapture }) => {
  const videoRef = useRef(null);
  const audioContextRef = useRef(null);
  const sourceNodeRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleCanPlay = useCallback(() => {
    console.log('Stream can play');
    setIsLoading(false);
    
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    }

    if (!sourceNodeRef.current && videoRef.current) {
      try {
        sourceNodeRef.current = audioContextRef.current.createMediaElementSource(videoRef.current);
        sourceNodeRef.current.connect(audioContextRef.current.destination);

        const processor = audioContextRef.current.createScriptProcessor(1024, 1, 1);
        sourceNodeRef.current.connect(processor);
        processor.connect(audioContextRef.current.destination);

        processor.onaudioprocess = (e) => {
          const inputData = e.inputBuffer.getChannelData(0);
          onAudioCapture(inputData);
        };
      } catch (err) {
        console.error('Error setting up audio processing:', err);
      }
    }
  }, [onAudioCapture]);

  const handleError = useCallback((e) => {
    console.error('Stream loading error:', e);
    setIsLoading(false);
    setError('Failed to load the stream. Please check the URL and try again.');
  }, []);

  useEffect(() => {
    console.log('Attempting to load stream:', streamUrl);
    let hls;
    let dashPlayer;

    const loadStream = async () => {
      setIsLoading(true);
      setError(null);

      if (!videoRef.current) return;

      try {
        if (Hls.isSupported() && streamUrl.includes('.m3u8')) {
          hls = new Hls();
          hls.loadSource(streamUrl);
          hls.attachMedia(videoRef.current);
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            videoRef.current.play();
          });
        } else if (dashjs.supportsMediaSource() && streamUrl.includes('.mpd')) {
          dashPlayer = dashjs.MediaPlayer().create();
          dashPlayer.initialize(videoRef.current, streamUrl, true);
        } else {
          videoRef.current.src = streamUrl;
        }
      } catch (error) {
        handleError(error);
      }
    };

    if (streamUrl) {
      loadStream();
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
      if (dashPlayer) {
        dashPlayer.reset();
      }
      if (audioContextRef.current) {
        audioContextRef.current.close();
        audioContextRef.current = null;
      }
      if (sourceNodeRef.current) {
        sourceNodeRef.current.disconnect();
        sourceNodeRef.current = null;
      }
    };
  }, [streamUrl, handleError]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('canplay', handleCanPlay);
      videoElement.addEventListener('error', handleError);

      return () => {
        videoElement.removeEventListener('canplay', handleCanPlay);
        videoElement.removeEventListener('error', handleError);
      };
    }
  }, [handleCanPlay, handleError]);

  return (
    <div className="live-stream-embed relative w-full h-full">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-200">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      )}
      {error && (
        <div className="absolute inset-0 flex items-center justify-center bg-red-100 text-red-600 p-4">
          {error}
        </div>
      )}
      <video 
        ref={videoRef} 
        controls 
        className={`w-full h-full ${isLoading || error ? 'hidden' : ''}`}
      />
    </div>
  );
});

export default LiveStreamEmbed;
