import React, { useState, useCallback, useMemo, useEffect, lazy, Suspense } from 'react';
import LiveStreamEmbed from './LiveStreamEmbed';
import TranslationOverlay from './TranslationOverlay';
import { auth, db } from '../firebase';
import { signOut, updateProfile } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { FaCog, FaBars } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHotkeys } from 'react-hotkeys-hook';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const SettingsPanel = lazy(() => import('./SettingsPanel'));
const PaymentModal = lazy(() => import('./PaymentModal'));

const TEST_URLS = [
  { label: 'HLS Test Stream', url: 'https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8' },
  { label: 'DASH Test Stream', url: 'https://dash.akamaized.net/akamai/bbb_30fps/bbb_30fps.mpd' },
  { label: 'MP4 Test Video', url: 'https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/720/Big_Buck_Bunny_720_10s_1MB.mp4' },
  { label: 'Invalid URL', url: 'https://example.com/invalid-stream.m3u8' },
];

const stripePromise = loadStripe('your_stripe_publishable_key');

const TranslationApp = () => {
  const [settings, setSettings] = useState({
    darkMode: false,
    fontSize: 'medium',
    defaultLanguage: 'English',
    targetLanguage: 'en',
    initialPosition: { x: 10, y: 10 },
    initialSize: { width: 300, height: 200 },
  });

  const [audioData, setAudioData] = useState(null);
  const [showSettings, setShowSettings] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [streamUrl, setStreamUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [translationCache, setTranslationCache] = useState({});
  const [translationHistory, setTranslationHistory] = useState([]);
  const [streamStartTime, setStreamStartTime] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isPremiumUser, setIsPremiumUser] = useState(false);

  useEffect(() => {
    const loadUserSettings = async () => {
      if (auth.currentUser) {
        try {
          const userDocRef = doc(db, 'users', auth.currentUser.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            setSettings(userDoc.data().settings || {});
            setDisplayName(auth.currentUser.displayName || '');
            toast.success('Settings loaded successfully');
          } else {
            await setDoc(userDocRef, { settings });
          }
        } catch (error) {
          console.error('Error loading user settings:', error);
          toast.error('Failed to load settings');
        }
      }
    };
    loadUserSettings();
  }, [auth.currentUser]);

  useEffect(() => {
    let timer;
    if (streamStartTime && !isPremiumUser) {
      timer = setTimeout(() => {
        setShowPaymentModal(true);
      }, 10 * 60 * 1000); // 10 minutes
    }
    return () => clearTimeout(timer);
  }, [streamStartTime, isPremiumUser]);

  const handleAudioCapture = useCallback((data) => {
    setAudioData(data);
  }, []);

  const handleSettingsChange = useCallback(async (newSettings) => {
    setSettings(newSettings);
    if (auth.currentUser) {
      try {
        await setDoc(doc(db, 'users', auth.currentUser.uid), { settings: newSettings }, { merge: true });
        toast.success('Settings saved successfully');
      } catch (error) {
        console.error('Error saving user settings:', error);
        toast.error('Failed to save settings');
      }
    }
  }, []);

  const handleDisplayNameChange = useCallback(async (newDisplayName) => {
    if (auth.currentUser) {
      try {
        await updateProfile(auth.currentUser, { displayName: newDisplayName });
        setDisplayName(newDisplayName);
        toast.success('Display name updated successfully');
      } catch (error) {
        console.error('Error updating display name:', error);
        toast.error('Failed to update display name');
      }
    }
  }, []);

  const handleStreamUrlSelect = useCallback((e) => {
    setStreamUrl(e.target.value);
  }, []);

  const handleSignOut = useCallback(async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  }, []);

  const addToHistory = useCallback((original, translated) => {
    setTranslationHistory(prev => [{ original, translated, timestamp: new Date() }, ...prev].slice(0, 10));
  }, []);

  const handlePositionChange = useCallback((newPosition) => {
    setSettings(prevSettings => ({
      ...prevSettings,
      initialPosition: newPosition
    }));
  }, []);

  const overlayProps = useMemo(() => ({
    position: settings.initialPosition,
    fontSize: settings.fontSize === 'small' ? 12 : settings.fontSize === 'medium' ? 16 : 20,
    backgroundColor: settings.darkMode ? 'bg-gray-800' : 'bg-white',
    textColor: settings.darkMode ? 'text-white' : 'text-gray-900',
    targetLanguage: settings.targetLanguage,
    translationCache,
    setTranslationCache,
    onPositionChange: handlePositionChange,
    addToHistory,
  }), [settings, translationCache, handlePositionChange, addToHistory]);

  const appClassName = useMemo(() => 
    `min-h-screen p-4 ${settings.darkMode ? 'dark bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`,
    [settings.darkMode]
  );

  const handleError = useCallback((error, message) => {
    console.error(message, error);
    toast.error(message);
  }, []);

  const handleStreamUrlSubmit = useCallback((e) => {
    e.preventDefault();
    const url = e.target.elements.streamUrl.value || e.target.value;
    setIsLoading(true);
    setError(null);

    // Replace this with actual stream loading logic
    setTimeout(() => {
      if (url.startsWith('http://') || url.startsWith('https://')) {
        setStreamUrl(url);
        setIsLoading(false);
        toast.success('Stream loaded successfully');
      } else {
        setError('Invalid URL. Please enter a valid stream URL.');
        setIsLoading(false);
        toast.error('Failed to load stream: Invalid URL');
      }
    }, 1500);
  }, []);

  const handleStreamStart = useCallback(() => {
    setStreamStartTime(Date.now());
  }, []);

  const handlePaymentSuccess = useCallback(() => {
    setIsPremiumUser(true);
    setShowPaymentModal(false);
  }, []);

  useHotkeys('ctrl+s', () => setShowSettings(prev => !prev), []);

  return (
    <div className={appClassName}>
      <ToastContainer position="top-right" autoClose={3000} />
      <header className="flex justify-between items-center mb-8 p-4 bg-blue-600 text-white rounded-lg shadow-md">
        <h1 className="text-xl sm:text-2xl font-bold">Live Stream Translator</h1>
        <div className="flex items-center space-x-4">
          <span>{displayName || auth.currentUser.email}</span>
          <button
            onClick={() => setShowSettings(!showSettings)}
            className="p-2 bg-blue-500 rounded-full hover:bg-blue-600 transition-colors"
            aria-label="Toggle settings"
          >
            <FaCog size={20} />
          </button>
          <button onClick={handleSignOut} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors">Sign Out</button>
        </div>
        <button
          className="sm:hidden p-2 bg-blue-500 rounded-full hover:bg-blue-600 transition-colors"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          aria-label="Toggle mobile menu"
        >
          <FaBars size={20} />
        </button>
      </header>
      {isMobileMenuOpen && (
        <div className="sm:hidden mb-4 p-4 bg-blue-500 rounded-lg">
          <button
            onClick={() => {
              setShowSettings(!showSettings);
              setIsMobileMenuOpen(false);
            }}
            className="block w-full text-left py-2 text-white hover:bg-blue-600 transition-colors"
          >
            Settings
          </button>
          <button
            onClick={handleSignOut}
            className="block w-full text-left py-2 text-white hover:bg-blue-600 transition-colors"
          >
            Sign Out
          </button>
        </div>
      )}
      <main className="relative">
        <form onSubmit={handleStreamUrlSubmit} className="flex flex-col sm:flex-row justify-center mb-8">
          <select
            value={streamUrl}
            onChange={handleStreamUrlSelect}
            className="w-full sm:w-1/3 p-2 border border-gray-300 rounded-lg sm:rounded-l-lg sm:rounded-r-none mb-2 sm:mb-0 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          >
            <option value="">Select a test stream</option>
            {TEST_URLS.map((stream, index) => (
              <option key={index} value={stream.url}>{stream.label}</option>
            ))}
          </select>
          <input
            type="text"
            name="streamUrl"
            placeholder="Or enter custom stream URL"
            value={streamUrl}
            onChange={(e) => setStreamUrl(e.target.value)}
            className="w-full sm:w-1/3 p-2 border border-gray-300 rounded-lg sm:rounded-none mb-2 sm:mb-0 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          />
          <button 
            type="submit" 
            className="w-full sm:w-auto px-4 py-2 bg-green-500 text-white rounded-lg sm:rounded-l-none sm:rounded-r-lg hover:bg-green-600 transition-colors disabled:bg-gray-400"
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Load Stream'}
          </button>
        </form>
        {error && (
          <div className="text-red-500 mb-4 text-center">{error}</div>
        )}
        <div className="relative w-full max-w-3xl mx-auto mb-8 border-2 border-blue-500 rounded-lg overflow-hidden shadow-lg">
          <LiveStreamEmbed
            streamUrl={streamUrl}
            onAudioCapture={handleAudioCapture}
            onStreamStart={handleStreamStart}
          />
          {streamUrl && (
            <TranslationOverlay
              audioData={audioData}
              {...overlayProps}
            />
          )}
        </div>
        <Suspense fallback={<div>Loading...</div>}>
          {showSettings && (
            <SettingsPanel 
              settings={settings} 
              onSettingsChange={handleSettingsChange}
              displayName={displayName}
              onDisplayNameChange={handleDisplayNameChange}
              onClose={() => setShowSettings(false)} 
            />
          )}
        </Suspense>
        <div className="mt-8">
          <h3 className="text-xl font-semibold mb-4">Translation History</h3>
          <ul className="space-y-2">
            {translationHistory.map((item, index) => (
              <li key={index} className="bg-gray-100 p-2 rounded">
                <p><strong>Original:</strong> {item.original}</p>
                <p><strong>Translated:</strong> {item.translated}</p>
                <p><small>{item.timestamp.toLocaleString()}</small></p>
              </li>
            ))}
          </ul>
        </div>
        <Elements stripe={stripePromise}>
          <Suspense fallback={<div>Loading...</div>}>
            {showPaymentModal && (
              <PaymentModal
                onClose={() => setShowPaymentModal(false)}
                onPaymentSuccess={handlePaymentSuccess}
              />
            )}
          </Suspense>
        </Elements>
      </main>
    </div>
  );
};

export default React.memo(TranslationApp);
