import axios from 'axios';

const API_URL = 'http://localhost:5000/api';

export const detectLanguage = async (text) => {
  try {
    const response = await axios.post(`${API_URL}/detect-language`, { text });
    return response.data.language;
  } catch (error) {
    console.error('Language detection error:', error);
    return 'en'; // Default to English if detection fails
  }
};

export const speechToText = async (audioData) => {
  try {
    const formData = new FormData();
    formData.append('audio', new Blob([audioData], { type: 'audio/wav' }), 'audio.wav');

    const response = await axios.post(`${API_URL}/speech-to-text`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    return response.data.text;
  } catch (error) {
    console.error('Speech-to-text error:', error);
    return '';
  }
};

export const translateText = async (text, targetLanguage) => {
  try {
    const sourceLanguage = await detectLanguage(text);
    const response = await axios.post(`${API_URL}/translate`, { text, sourceLanguage, targetLanguage });
    return response.data.translatedText;
  } catch (error) {
    console.error('Translation error:', error);
    return '';
  }
};
