import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Draggable from 'react-draggable';
import { speechToText, translateText } from '../services/translationService';
import debounce from 'lodash/debounce';
import { FaExpandArrowsAlt, FaCompressArrowsAlt } from 'react-icons/fa';

const TranslationOverlay = React.memo(({ audioData, position, fontSize, backgroundColor, textColor, targetLanguage, translationCache, setTranslationCache, onPositionChange, addToHistory }) => {
  const [originalText, setOriginalText] = useState('');
  const [translatedText, setTranslatedText] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isPiP, setIsPiP] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const processAudio = useCallback(
    debounce(async (audioData) => {
      if (!audioData || isProcessing) return;

      setIsProcessing(true);
      try {
        const text = await speechToText(audioData);
        if (text && text !== originalText) {
          setOriginalText(text);
          
          const cacheKey = `${text}|${targetLanguage}`;
          if (translationCache[cacheKey]) {
            setTranslatedText(translationCache[cacheKey]);
          } else {
            const translated = await translateText(text, targetLanguage);
            setTranslatedText(translated);
            setTranslationCache(prev => ({ ...prev, [cacheKey]: translated }));
          }
          addToHistory(text, translated);
        }
      } catch (error) {
        console.error('Error processing audio:', error);
      } finally {
        setIsProcessing(false);
      }
    }, 500),
    [targetLanguage, originalText, isProcessing, translationCache, setTranslationCache, addToHistory]
  );

  useEffect(() => {
    processAudio(audioData);
  }, [processAudio, audioData]);

  const handleDrag = useCallback((e, data) => {
    onPositionChange({ x: data.x, y: data.y });
  }, [onPositionChange]);

  const togglePiP = useCallback(() => {
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture();
    } else if (document.pictureInPictureEnabled) {
      const pipWindow = document.createElement('div');
      pipWindow.innerHTML = `
        <div style="padding: 10px; background-color: ${backgroundColor}; color: ${textColor};">
          <div>${originalText}</div>
          <div>${translatedText}</div>
        </div>
      `;
      pipWindow.requestPictureInPicture();
    }
    setIsPiP(!isPiP);
  }, [backgroundColor, textColor, originalText, translatedText, isPiP]);

  const toggleExpand = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const contentClass = useMemo(() => `text-${fontSize} mb-2`, [fontSize]);

  return (
    <Draggable position={position} onDrag={handleDrag} bounds="parent">
      <div className={`absolute p-4 rounded-lg shadow-lg overflow-hidden cursor-move ${backgroundColor} ${textColor} ${isExpanded ? 'w-full h-full' : 'max-w-[80%]'}`}>
        <div className="flex justify-between items-center mb-2">
          <button onClick={togglePiP} className="px-2 py-1 bg-blue-500 text-white rounded">
            {isPiP ? 'Exit PiP' : 'Enter PiP'}
          </button>
          <button onClick={toggleExpand} className="px-2 py-1 bg-blue-500 text-white rounded">
            {isExpanded ? <FaCompressArrowsAlt /> : <FaExpandArrowsAlt />}
          </button>
        </div>
        <div className={contentClass}>
          {isProcessing ? (
            <div className="flex items-center justify-center">
              <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Processing...
            </div>
          ) : (
            <>
              <div className="font-semibold mb-1">Original:</div>
              <div>{originalText}</div>
            </>
          )}
        </div>
        {translatedText && (
          <div className={`${contentClass} border-t pt-2`}>
            <div className="font-semibold mb-1">Translated:</div>
            <div>{translatedText}</div>
          </div>
        )}
      </div>
    </Draggable>
  );
});

export default TranslationOverlay;
